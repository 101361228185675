// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from "react"
import { connect } from "react-redux"

import { Link } from "gatsby"
import { navigate } from "@reach/router"

import { FaSpinner } from "react-icons/fa"
import Layout from "../components/layout"
import axios from "axios"

import moment from "moment"

// import Amplify, { Auth, Cache } from "aws-amplify"
import Amplify from "@aws-amplify/core"
import API from "@aws-amplify/api"
import Auth from "@aws-amplify/auth"
import Cache from "@aws-amplify/cache"

import { amplifyconfig } from "../utils/config"

import ForceNewPassword from "../components/forceNewPassword"

import { setUserData } from "../actions/setUserData"

import "../scss/main.scss"

Amplify.configure(amplifyconfig)

// PRODUCTION
const serviceUrl = process.env.GATSBY_API_URL
const serviceUrlWeb = process.env.GATSBY_API_URL_WEB

// const mapStateToProps = ({ mailmerge, mailmergeusers, isLoading }) => {
//   return { mailmerge, mailmergeusers, isLoading }
// }

const mapDispatchToProps = dispatch => ({
  setUserData: user => dispatch(setUserData(user)),
})

// DEVELOPMENT
// const serviceUrl = 'http://localhost:3000/';

// Amplify.configure({
//     Auth: {
//         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//         // identityPoolId: 'us-east-1:b08cc34e-52e9-4b09-895f-f91c0da999b2',

//         // REQUIRED - Amazon Cognito Region
//         region: 'us-east-1',

//         // OPTIONAL - Amazon Cognito User Pool ID
//         userPoolId: 'us-east-1_LrTlrJeTL',

//         userPoolWebClientId: '72ois8o2ra0qe1lvblu2gh8urc',
//     },
// })

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      username: ``,
      password: ``,
      loading: false,
      error: [],
      forceNewPassword: false,
    }
  }

  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  handleSubmit = () => {
    this.setState({ loading: true })
    this.login()
  }
  login = async () => {
    const { username, password } = this.state
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      crossDomain: true,
    }

    console.log(encodeURIComponent(username))

    try {
      const admin = await axios.get(
        `${serviceUrlWeb}adminauth?email=` + encodeURIComponent(username),
        config
      )

      console.log("((admin))")
      console.log(admin)

      if (
        admin &&
        admin.data &&
        admin.data.role &&
        admin.data.role === "superuser" &&
        admin.data.cid === "b063c32f-cd4a-45c2-b686-111f6d0d2a05"
      ) {
        console.log("admin")
        console.log(admin)
        console.log("admin is TRUE")

        const expiration = moment()
          .add(1, "day")
          .valueOf()
        console.log("expiration")
        console.log(expiration)
        Cache.setItem("cid", admin.data.cid, { expires: expiration })
        Cache.setItem("company", admin.data.company, { expires: expiration })

        const userLogin = await Auth.signIn(username, password)

        if (userLogin.challengeName === "NEW_PASSWORD_REQUIRED") {
          this.setState({
            forceNewPassword: true,
            userobject: userLogin,
          })
        } else {
          const user = await Auth.currentAuthenticatedUser()
          // const userInfo = {
          //   ...user.attributes,
          //   username: user.username
          // }
          console.log("------****** user ******-------")
          console.log(user)
          // setUser(userInfo)
          this.props.setUserData(user)
          this.setState({ loading: false })
          console.log("--sending to app/home")
          navigate("/app/home/")
        }
      } else {
        //then is not admin
        console.log("admin is false**")

        const adminError = {
          code: "AdminError",
          message: "Oops, you don't have access to that page.",
          name: "AdminError",
        }
        this.setState({ loading: false })
        this.setState({ error: adminError })
      } //end admin if

      // }
    } catch (err) {
      this.setState({ loading: false })

      console.log("error...: ", err)
      this.setState({ error: err })
    } //end try/catch
  }

  render() {
    return (
      <Layout>
        <div class="container container-page container-login d-flex flex-column justify-content-center align-items-center flex-grow-1">
          <div class="row align-items-center justify-content-center login-loading d-none">
            <div class="text-center">
              <i class="fa fa-spinner fa-spin fa-3x" />
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="">
              {this.state.forceNewPassword ? (
                <ForceNewPassword userobject={this.state.userobject} />
              ) : (
                <form class="form-signin">
                  {this.state.loading === true ? (
                    <div class="d-flex align-items-center justify-content-center p-4">
                      <FaSpinner size={30} className="icon-spin" />
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.error.message ? (
                    <div class="alert alert-danger text-center" role="alert">
                      {this.state.error.message}
                    </div>
                  ) : (
                    ""
                  )}

                  <h2 class="form-signin-heading text-center pb-2">
                    Dashboard
                  </h2>
                  {/* <label class="sr-only">Email</label> */}

                  <input
                    class="form-control mb-2"
                    onChange={this.handleUpdate}
                    placeholder="Email"
                    name="username"
                    value={this.state.username}
                    tabIndex="1"
                  />

                  {/* <label class="sr-only">Password</label> */}
                  <input
                    class="form-control mb-2"
                    onChange={this.handleUpdate}
                    placeholder="Password"
                    name="password"
                    value={this.state.password}
                    type="password"
                    tabIndex="2"
                  />

                  <button
                    type="button"
                    class="btn btn-primary btn-block mt-2 app-button-signin"
                    onClick={this.handleSubmit}
                    id="app-login-submit"
                    tabIndex="3"
                  >
                    Sign in
                  </button>
                  {/* <Link class="btn btn-link btn-block my-3" to="/app/password" tabIndex="4">Forgot password?</Link> */}
                </form>
              )}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default connect(null, mapDispatchToProps)(Login)

// export default Login
